import React from 'react'
import { Container, Grid, Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import "./style.css";
import { Col, Row } from 'react-bootstrap';
import useStyles from './styles';
import axios from 'axios';
import { auth, provider } from "../Google_login/config";
import { signInWithPopup } from "firebase/auth";
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const generateRandomDeviceId = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let deviceId = '';

  for (let i = 0; i < 10; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    deviceId += characters.charAt(randomIndex);
  }

  return deviceId;
};





const Confirm_checkout = () => {

    const [isAuthenticated, setIsAuthenticated] = useState(false);


    // Access the history object using the useHistory hook
    const history = useHistory();
    const couponRef = useRef();

    const handleCoupon = async (e) => {
      e.preventDefault();
      

      try {
      
        const response = await axios.post(`https://fourband-prod-on2dz.ondigitalocean.app/v1/data/verify-coupon/${couponRef.current.value}/`);
      
          // Check the response status code for success (e.g., HTTP 200)
          if (response.status === 200 || response.status === 201) {
           
            console.log('response:', response.data);
       
          } else {
            // Handle unexpected response status codes
            console.error('Unexpected response:', response.status);
            // alert('An unexpected error occurred. Please try again later.');
          }
        } catch (error) {
          // Handle network or request errors
          console.error('Error:', error);
      
          // Check if the error is due to unauthorized access (401)
          if (error.response && error.response.status === 401) {
            alert('Unauthorized access. Please log in again.');
            // You might want to redirect the user to the login page here
            // window.location='/';
          } else {
            // Handle other errors
            alert('An error occurred. Please try again later.');
          }
        }


    }
    

    const handleTicketChange = (event) => {
        const eventPrice = event.target.value;
        setOrgPrice(eventPrice)
        setQuantity(1)
    }


    
    const classes = useStyles();

    const [product, setProduct] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [orgPrice, setOrgPrice] = useState();
    const [currentPrice, setCurrentPrice] = useState();
    const [ticketType, setTicketType] = useState([]);

    const { id, price, tType } = useParams();

    useEffect(() => {

        // Fetch products when the component mounts
        axios.get("https://fourband-prod-on2dz.ondigitalocean.app/v1/data/events-list/").then((response) => {
          const reslutArray = response.data.data.results;
          const singleEvent = reslutArray.filter((event) => event.id == id);

          setProduct(singleEvent[0]);
          setOrgPrice(price);
          setCurrentPrice(price)
          setTicketType(singleEvent[0].ticket_types?.pricing)
        });
      }, [id, price]);

      const handleConfirmCheckout = async () => {
        try {
          // Check if user is already authenticated
          const cachedToken = localStorage.getItem('dummy_token2');
          if (cachedToken) {
            setIsAuthenticated(true);
            history.push(`/final-form/${product.id}/${quantity}/${orgPrice}/${tType}/${product.handling_fee}`);
            return;
          }
      
          // If not authenticated, sign in with Google using Firebase
          const result = await signInWithPopup(auth, provider);
          const { email, uid, accessToken } = result.user;
          const deviceId = generateRandomDeviceId();
      
          // Send the ID token and additional data to the API
          const response = await axios.post(
            'https://fourband-prod-on2dz.ondigitalocean.app/v1/data/auth/login/', { email, device_id: deviceId, uid }
          );
      
          if ((response.status === 200 && response.data.message === 'User Login success') || (response.status === 201 && response.data.message === 'User added successfully')) {
            // Successfully sent credentials to the API
            console.log(response)
            const token = response.data.data.token;
            localStorage.setItem('dummy_token2', token);
            setIsAuthenticated(true);
            history.push(`/final-form/${product.id}/${quantity}/${orgPrice}/${tType}/${product.handling_fee}`);
            // Redirect to the checkout page
          } else {
            // Handle error
            console.error('Failed to send credentials to API. Status:', response.status);
            console.error('Error response:', response.data);
          }
        } catch (error) {
          console.error('Error during authentication:', error);
        }
      };

  return (
    <Container className='confirm_page'>
        <Row className='center'>
            <Col sm={12}  className='center'>
                <div className="confirm_box shadow" > 
                    <h4>CONFIRM YOUR BOOKING</h4>   

                            <div className='custom_div'>
                            <label>Event</label>
                            <p><b>{product.title}</b></p> 
                            <label>Date</label>
                            <p><b>{product.date}</b></p>
                            <label>Time</label>
                            <p><b>{product.time}</b></p>
                            <label>Add Ticket Quantity</label>
                            <div className={classes.buttons}>
                            {quantity > 1 && 
                            <Button type="button" size="small"  onClick={() =>{
                                setQuantity(quantity - 1) 
                                setOrgPrice(parseInt(orgPrice) - parseInt(currentPrice))
                                }}>-</Button>
                            }   
                            <Typography>&nbsp;{quantity}&nbsp;</Typography>
                            <Button type="button" size="small" onClick={() =>{

                            setQuantity(quantity + 1) 
                            setOrgPrice(parseInt(orgPrice) + parseInt(currentPrice))
                            }}>+</Button>
                            </div>
                            <div className='custom_hr'>
                                </div>
                            <span className='end'>Total Amount <h5><b>₹{orgPrice}</b></h5></span>
                            
                            <Button
                            variant="contained"
                            className={classes.button}
                            onClick={handleConfirmCheckout}
                            // component={Link}
                            // to={"/checkout/"+JSON.stringify({'id':product.id,'total':orgPrice})}
                            style={{ backgroundColor: "#FF1E1E", color: "white" }}
                            >
                            <b>Confirm</b>
                            </Button>
                            </div>

                </div>

            </Col>
        </Row>
      
    </Container >
  )
}

export default Confirm_checkout
