import React from 'react'
import { Container, Grid, Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { commerce } from "../../lib/commerce";
import { useState, useEffect } from "react";
import "./style.css";
import { Col, Row } from 'react-bootstrap';
import useStyles from './styles';
import axios from 'axios';
import { useParams } from 'react-router-dom';


const Confirm_checkout = () => {

    const handleTicketChange = (event) => {
        const eventPrice = event.target.value;
        setOrgPrice(eventPrice)
        setQuantity(1)
    }


    
    const classes = useStyles();

    const [product, setProduct] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [orgPrice, setOrgPrice] = useState();
    const [currentPrice, setCurrentPrice] = useState();
    const [ticketType, setTicketType] = useState([]);

    const { id, price } = useParams();
    
    useEffect(() => {
        // Fetch products when the component mounts
        axios.get("https://fourband-prod-on2dz.ondigitalocean.app/v1/data/events-list/").then((response) => {
          const reslutArray = response.data.data.results;
          const singleEvent = reslutArray.filter((event) => event.id == id);
          
          // Move the code inside the .then callback
          setProduct(singleEvent[0]);
          setOrgPrice(price);
          setCurrentPrice(price)
          console.log('singleEvent[0].ticket_types: ', singleEvent[0].ticket_types);
          setTicketType(singleEvent[0].ticket_types?.pricing)
          console.log('dbhjsabdshbds...', singleEvent);
        });
      }, []);

    const fetchProduct = async (id) => {
        const response = await commerce.products.retrieve(id);
        console.log("response:::::",{ response });
        const { name, price, media, quantity, description } = response;
        setProduct({
          id,
          name,
          quantity,
          description,
          src: media.source,
          price: price.formatted_with_symbol,
          default_price: price.formatted,
        });
      };


    // useEffect(() => {
    // const id = window.location.pathname.split("/");
    // fetchProduct(id[2]);
    // }, []);

    console.log("ticketType",ticketType);
  return (
    <Container className='confirm_page'>
        <Row className='center'>
            <Col sm={12} md={6}  className='center'>
               
                <div className='holder_catg shadow'>
                    <h4 className='t_white'>Select Your Ticket Type</h4>
                    {ticketType.map((ticket,i)=>(
                    <div key={i} >
                        <div className='holder_sub_div'>
                        <div className='holder_leftside'>
                            <span className='t_white'><b>{ticket.type}</b></span>
                            <span className='t_white'>₹ {ticket.price}</span>
                        </div>
                        <div>
                        {ticket?.count > 1 ?
                        (
                          <Button 
                          className='cust_btn'
                          component={Link}
                          to={`/confirm-checkout/${id}/${ticket.price}/${ticket.type}`}
                          >Select</Button>
                          ):
                          (
                            <><p style={{textAlign:"center", color:"white", background:"red", padding:"2px 4px", borderRadius:"4px", marginBottom:"-4px"}}>Sold Out!</p>  </>
                          )
                          }
                          
                        </div>
                        </div>
                    </div>
                    ))}
                </div>

            </Col>
        </Row>
      
    </Container >
  )
}

export default Confirm_checkout
