import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/circles.png";
import bookingIcon from "../../assets/booking-forshow.svg";
import logoutIcon from "../../assets/logout.svg";
import loginIcon from "../../assets/login-forshow.svg"; // Importing images
import useStyles from "./styles";
import axios from "axios";
import { auth, provider } from "../Google_login/config";
import { signInWithPopup } from "firebase/auth";
import { useMediaQuery, useTheme } from "@material-ui/core";

const Navbar = ({ totalItems }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(Boolean(localStorage.getItem("dummy_token2")));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const generateRandomDeviceId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let deviceId = '';
  
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      deviceId += characters.charAt(randomIndex);
    }
  
    return deviceId;
  };

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const { email, uid } = result.user;
      const deviceId = generateRandomDeviceId();

      // Send the ID token and additional data to the API
      const response = await axios.post(
        'https://fourband-prod-on2dz.ondigitalocean.app/v1/data/auth/login/',
        { email, device_id: deviceId, uid }
      );

      if ((response.status === 200 && response.data.message === 'User Login success') || (response.status === 201 && response.data.message === 'User added successfully')) {
        const token = response.data.data.token;
        localStorage.setItem('dummy_token2', token);
        setIsAuthenticated(true);
        history.push(`/`); // Redirect to home page after successful login
      } else {
        console.error('Failed to send credentials to API. Status:', response.status);
      }
    } catch (error) {
      console.error('Error during authentication:', error);
    }
  };

  const handleLogout = async () => {
    const confirmed = window.confirm("Are you sure you want to logout?");
    if (!confirmed) return; // Exit if the user clicks "Cancel"

    const token = localStorage.getItem('dummy_token2');
    const headers = {
      'Authorization': `Token ${token}`,
      'Content-Type': 'application/json',
    };

    try {
      // Call the logout API
      await axios.post(
        'https://fourband-prod-on2dz.ondigitalocean.app/v1/data/auth/logout/',
        {},
        { headers }
      );

      // Clear token from localStorage and update state
      localStorage.removeItem('dummy_token2');
      setIsAuthenticated(false);
      history.push(`/`); // Redirect to home page
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar} color="inherit">
        <Toolbar>
          <Typography
            component={Link}
            to="/"
            variant="h5"
            className={classes.title}
            color="inherit"
          >
            <img
              src={logo}
              alt="Book Store App"
              height="50px"
              className={classes.image}
            />
          </Typography>

          <div className={classes.grow} />

          <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            {isAuthenticated ? (
              <>
                <Button
                  component={Link}
                  to="/booking"
                  color="inherit"
                  style={{
                    fontSize: isMobile ? '0.9rem' : '1.1rem',
                    padding: isMobile ? '5px 10px' : '10px 20px',
                    marginRight: '10px',
                    textTransform: 'none', // Prevents text capitalization
                  }}
                >
                  Bookings
                  <img
                    src={bookingIcon}
                    alt="Bookings Icon"
                    style={{ width: '20px', height: '20px', marginLeft: '5px' }}
                  />
                </Button>
                <Button
                  onClick={handleLogout}
                  color="inherit"
                  style={{
                    fontSize: isMobile ? '0.9rem' : '1.1rem',
                    padding: isMobile ? '5px 10px' : '10px 20px',
                    marginRight: '10px',
                    textTransform: 'none', // Prevents text capitalization
                  }}
                >
                  Logout
                  <img
                    src={logoutIcon}
                    alt="Logout Icon"
                    style={{ width: '20px', height: '20px', marginLeft: '5px' }}
                  />
                </Button>
              </>
            ) : (
              <Button
                onClick={handleLogin}
                color="inherit"
                style={{
                  fontSize: isMobile ? '0.9rem' : '1.2rem',
                  padding: isMobile ? '5px 10px' : '10px 20px',
                  marginRight: '10px',
                  textTransform: 'none', // Prevents text capitalization
                }}
              >
                Login
                <img
                  src={loginIcon}
                  alt="Login Icon"
                  style={{ width: '26px', height: '26px', marginLeft: '5px' }}
                />
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
