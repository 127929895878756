// config.js

import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  // Your Firebase config here
  apiKey: "AIzaSyAXRHqW0k_c0nXBL9mi7UCN__eDvl_zO6I",
  authDomain: "foursho-421e9.firebaseapp.com",
  projectId: "foursho-421e9",
  storageBucket: "foursho-421e9.appspot.com",
  messagingSenderId: "292665778463",
  appId: "1:292665778463:web:3a209551318584789bd02a",
  measurementId: "G-20QTTMJBPW"

};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getFirestore(app)
const provider = new GoogleAuthProvider();

export { auth, provider, database };
