import React, { useState, useEffect } from 'react';
import { CssBaseline, Paper, Stepper, Step, StepLabel, Typography, CircularProgress, Divider, Button } from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';


import { commerce } from '../../../lib/commerce';
import AddressForm from '../AddressForm';
import PaymentForm from '../PaymentForm';
import useStyles from './styles';
import axios from 'axios';

const steps = ['User details', 'Payment details'];

  

const Checkout = ({ cart, onCaptureCheckout, order, error }) => {




  const [checkoutToken, setCheckoutToken] = useState(null);
  const [locationid, setLocationID] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [shippingData, setShippingData] = useState({});
  const [product, setProduct] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const generateToken = async () => {
    // try {

      const pathnameSegments = location.pathname.split('/');
      const uniqueId =JSON.parse(pathnameSegments[pathnameSegments.length - 1]).id;
      const totalAmount =JSON.parse(pathnameSegments[pathnameSegments.length - 1]).total;
      console.log("date/....."+uniqueId);
      setLocationID(uniqueId)

      
      const token = product[0]
      console.log('data....',product);
      console.log('token....',token);
      console.log("Token",token);
      // console.log("New Token",newToken);
      
      // const token2 = await commerce.checkout.generateToken(cart.id, { type: 'cart' });
      // console.log(token2);
      token.price.formatted_with_symbol = '₹'+totalAmount;
      setCheckoutToken(token);
    // } catch {
    //   if (activeStep !== steps.length) history.push('/');
    // }
  };

  useEffect(() => {
    const pathnameSegments = location.pathname.split('/');
    const uniqueId =JSON.parse(pathnameSegments[pathnameSegments.length - 1]).id;
    const totalAmount =JSON.parse(pathnameSegments[pathnameSegments.length - 1]).total;
    setLocationID(uniqueId)
    
    // Fetch products when the component mounts
    axios.get("https://fourband-prod-on2dz.ondigitalocean.app/v1/data/events-list/").then((response) => {
      const reslutArray = response.data.data.results;
      const singleEvent = reslutArray.filter((event) => event.id == uniqueId);
      
      // Move the code inside the .then callback
      setProduct(singleEvent);
      const token = singleEvent[0]
      console.log('token....',token);
      token.ticket_types.pricing[0].price = '₹'+totalAmount;
      setCheckoutToken(token);
      // generateToken();

    });

  }, []);

  // useEffect(()=>{
  //   // getLocationId()
  //   generateToken();
  // },[])

  const nextStep = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const backStep = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  // useEffect(() => {
  //   if (cart.id) {
  //     const generateToken = async () => {
  //       try {
          

  //         const token = await commerce.checkout.generateToken(cart.id, { type: 'cart' });
  //         setCheckoutToken(token);
  //       } catch {
  //         if (activeStep !== steps.length) history.push('/');
  //       }
  //     };

  //     generateToken();
  //   }
  // }, [cart]);

  const test = (data) => {
    setShippingData(data);

    nextStep();
  };

  let Confirmation = () => (order.customer ? (
    <>
      <div>
        <Typography variant="h5">Thank you for your purchase, {order.customer.firstname} {order.customer.lastname}!</Typography>
        <Divider className={classes.divider} />
        <Typography variant="subtitle2">Order ref: {order.customer_reference}</Typography>
      </div>
      <br />
      <Button component={Link} variant="outlined" type="button" to="/">Back to home</Button>
    </>
  ) : (
    <div className={classes.spinner}>
      <CircularProgress />
    </div>
  ));

  if (error) {
    Confirmation = () => (
      <>
        <Typography variant="h5">Error: {error}</Typography>
        <br />
        <Button component={Link} variant="outlined" type="button" to="/">Back to home</Button>
      </>
    );
  }

  const Form = () => (activeStep === 0
    ? <AddressForm checkoutToken={checkoutToken} nextStep={nextStep} setShippingData={setShippingData} test={test} />
    : <PaymentForm checkoutToken={checkoutToken} nextStep={nextStep} backStep={backStep} shippingData={shippingData} onCaptureCheckout={onCaptureCheckout} />);

  return (
    <>
      <CssBaseline />
      <div className={classes.toolbar} />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant="h4" align="center">Checkout</Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? <Confirmation /> : checkoutToken && <Form />}
        </Paper>
      </main>
    </>
  );
};

export default Checkout;
