import React, { useState, useEffect, useRef } from 'react';
import { CssBaseline, Paper, Stepper, Step, StepLabel, Typography,Grid, CircularProgress, Divider, Button } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import FormInput from './CustomTextField';
import useStyles from './Checkout/styles';
import { useParams } from 'react-router-dom';
import axios from 'axios';


const AddressForm = () => {

  const { id, quantity, amount, ticket_type, handling_fee } = useParams();
  
  const classes = useStyles();
  const steps = ['User details', 'Payment details'];
  const [activeStep, setActiveStep] = useState(0);
  const [bookingID, setBooknigID] = useState('');
  const [loadingButton, setLoadingButton] = useState('NEXT');
  const methods = useForm();
  const nameRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const { handleSubmit, register } = methods;

  const getAuthToken = () => {
    return localStorage.getItem('dummy_token2');
  };

  const handlePayment = async (data2) => {
    try {
      const response2 = await axios.post('https://fourband-prod-on2dz.ondigitalocean.app/v1/data/create-payment-order/', data2, {
        headers: {
          'Token': `${getAuthToken()}`,
          'Authorization': `Token ${getAuthToken()}`,
          'Content-Type': 'application/json',
        },
      });

      console.log(response2.data);
      const paymentLink = response2.data.data.data;
      window.location.href = paymentLink;
      // Redirect to the desired route after successful payment
      // history.push('/success-route');  // Replace '/success-route' with your actual route

    } catch (error) {
      // Handle error
      console.error('Payment failed:', error);
    }
  };
  
  const onSubmit = async (val) => {

    let data={
      'event_id':parseInt(id),
      'quantity':parseInt(quantity),
      'amount':parseInt(amount),
      'ticket_type':ticket_type,
      'meta_data':{},
      'handling_fee':parseInt(handling_fee),
      'customer_email': val.email,
      'customer_phone_number': val.phone,
      'customer_name': val.name,
    }

    try {
      
      const response = await axios.post('https://fourband-prod-on2dz.ondigitalocean.app/v1/data/create-booking/', data, {
          headers: {
            'Token': `${getAuthToken()}`,
            'Authorization': `Token ${getAuthToken()}`,
            'Content-Type': 'application/json',
          },
        });
    
        // Check the response status code for success (e.g., HTTP 200)
        if (response.status === 200 || response.status === 201) {
          setBooknigID(response.data.data.booking_id)
          console.log('booking ID:', response.data.data.booking_id);
          const bID = response.data.data.booking_id

          const data2 = {
            "booking_id": bID,
            "quantity": parseInt(quantity),
            "amount": parseFloat(amount),
            "productinfo": "",
            "firstname": val.name,
            "email": val.email,
            "phone": val.phone,
            "surl":`https://foursho.com/event-qr/${bID}/${id}`,
            "furl":`https://foursho.com/`,
          }
          
          handlePayment(data2)
          // const response2 = await axios.post('https://api-dev-ticket.wecodelife.com/v1/data/create-payment-order/', data2, {
          //   headers: {
          //     'Token': `${getAuthToken()}`,
          //     'Authorization': `Token ${getAuthToken()}`,
          //     'Content-Type': 'application/json',
          //   },
          // });
          // console.log(response2.data);
          // const paymentLink = response2.data.data.data
          // window.location.href = paymentLink;
          // history.push('/success-route');
          // handleSuccess()
        } else {
          // Handle unexpected response status codes
          console.error('Unexpected response:', response.status);
          // alert('An unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle network or request errors
        console.error('Error:', error);
    
        // Check if the error is due to unauthorized access (401)
        if (error.response && error.response.status === 401) {
          alert('Unauthorized access. Please log in again.');
          // You might want to redirect the user to the login page here
          // window.location='/';
        } else {
          // Handle other errors
          alert('An error occurred. Please try again later.');
        }
      }

  }

  return (
    <>
        <CssBaseline />
      <div className={classes.toolbar} />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant="h4" align="center">Checkout</Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Typography variant="h6" gutterBottom>Enter details to continue</Typography>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} className='make-white'>
            <FormInput {...register('name')}   required name="name" label="Name"/>
            <FormInput {...register('phone')}   required name="phone" label="Mobile No" /> <br/>
            <FormInput {...register('email')}   type="email" required name="email" label="Email" />
            </Grid>
            <br/>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {/* <Button component={Link} variant="outlined" to="/" style={{ backgroundColor: 'black', color: 'white' }}>Home</Button> */}
              <Button type="submit" onClick={(e)=>setLoadingButton("Loading...")} variant="contained" color="primary" style={{ backgroundColor: 'red', color: 'white' }}>{loadingButton}</Button>
            </div>
        </form>
      </FormProvider>
        </Paper>
      </main>

    
    </>
  );
};

export default AddressForm;
