import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
  CardActionArea,
} from "@material-ui/core";
import { AddShoppingCart } from "@material-ui/icons";
import { Link } from "react-router-dom";
import useStyles from "./styles";

const Product = ({ product, onAddToCart }) => {
  const classes = useStyles();

  return (
    <Link to={`product-view/${product.id}`} style={{textDecoration: 'none'}}>
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={product.image}
            title={product.address}
          />
        </CardActionArea>

        <CardContent>
          <div className={classes.cardContent}>
            <Typography variant="h5" style={{ fontFamily: "Verdana" }}>
              {product.title}
            </Typography>
          </div>
          <br></br>
          <div className={classes.cardContent}>
            <Typography
              style={{ fontFamily: "Verdana", color: "red", fontSize: "15px" }}
            >
              {/* <b>{product.ticket_types.pricing[0].price} Onwards</b> */}
            </Typography>
          </div>
        </CardContent>
        <CardActions disableSpacing className={classes.cardActions}>
          {/* {product?.available_tickets > 1 ?
        (
          
          ):
            (
              <><p style={{textAlign:"center", color:"black", background:"yellow", padding:"2px 4px", borderRadius:"4px"}}>Tickets Sold Out!</p>  </>
            )
            } */}
          <Button
            variant="contained"
            className={classes.button}
            component={Link}
            to={`product-view/${product.id}`}
          >
            <b>BOOK NOW</b>
          </Button>
        </CardActions>
      </Card>
    </Link>
  );
};

export default Product;
